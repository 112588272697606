import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`TAGinstall is a 1-Click Google Tag Manager + Data Layer installer and 40+ marketing tags.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Everything should be made as simple as possible, but no simpler.” - Albert Einstein`}</p>
    </blockquote>
    <h2 {...{
      "id": "benefits",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#benefits",
        "aria-label": "benefits permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Benefits`}</h2>
    <h3 {...{
      "id": "quick--easy-to-install",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quick--easy-to-install",
        "aria-label": "quick  easy to install permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick & Easy to install`}</h3>
    <p>{`1-click Google Tag Manager and Data Layer installation. We've automated the process of installing over 40 of the most used marketing tags.`}</p>
    <h3 {...{
      "id": "improve-data-accuracy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#improve-data-accuracy",
        "aria-label": "improve data accuracy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improve data accuracy`}</h3>
    <p>{`Spent more of your time worrying about the things that matter, and we'll make sure your data has been gathered correctly.`}</p>
    <h3 {...{
      "id": "friendly-technical-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#friendly-technical-support",
        "aria-label": "friendly technical support permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Friendly Technical Support`}</h3>
    <p>{`Our team of tag experts is here to help you and ensure you get the most of your tag manager implementation. Don't be a stranger.`}</p>
    <h2 {...{
      "id": "why-top-marketers-use-google-tag-manager-installer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-top-marketers-use-google-tag-manager-installer",
        "aria-label": "why top marketers use google tag manager installer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why top marketers use Google Tag Manager Installer`}</h2>
    <p>{`Google Tag Manager Installer makes implementing GTM simple, fast, and accurate. Marketers love Google Tag Manager Installer as it ensures the data is gathered and reported correctly, taking the guesswork out of marketing. Start basing your decisions on accurate data and grow your e-commerce business.`}</p>
    <h2 {...{
      "id": "reasons-to-use-google-tag-manager-installer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reasons-to-use-google-tag-manager-installer",
        "aria-label": "reasons to use google tag manager installer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reasons to use Google Tag Manager Installer`}</h2>
    <ul>
      <li parentName="ul">{`Quick & Easy to install`}</li>
      <li parentName="ul">{`Improved data accuracy`}</li>
      <li parentName="ul">{`Free Technical Support`}</li>
      <li parentName="ul">{`Optimized for speed`}</li>
      <li parentName="ul">{`Save days of work`}</li>
      <li parentName="ul">{`Optional CCPA/GDPR compliance`}</li>
      <li parentName="ul">{`Server-side tagging`}</li>
    </ul>
    <h2 {...{
      "id": "what-you-get-with-google-tag-manager-installer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-get-with-google-tag-manager-installer",
        "aria-label": "what you get with google tag manager installer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you get with Google Tag Manager Installer`}</h2>
    <ul>
      <li parentName="ul">{`1-Click GTM installation`}</li>
      <li parentName="ul">{`Pre-built Data Layer`}</li>
      <li parentName="ul">{`Over 40 ready-made tags`}</li>
    </ul>
    <h2 {...{
      "id": "1-click-installation-available-for-the-following-tags",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-click-installation-available-for-the-following-tags",
        "aria-label": "1 click installation available for the following tags permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1-click Installation available for the following tags`}</h2>
    <ul>
      <li parentName="ul">{`Google Ads Conversions`}</li>
      <li parentName="ul">{`Google Ads Remarketing`}</li>
      <li parentName="ul">{`Google Analytics (Enhanced E-commerce)`}</li>
      <li parentName="ul">{`Facebook`}</li>
      <li parentName="ul">{`Snapchat`}</li>
      <li parentName="ul">{`Klaviyo`}</li>
      <li parentName="ul">{`Hotjar`}</li>
      <li parentName="ul">{`ManyChat`}</li>
      <li parentName="ul">{`Bing Ads`}</li>
      <li parentName="ul">{`LinkedIn`}</li>
      <li parentName="ul">{`Pinterest`}</li>
      <li parentName="ul">{`Twitter`}</li>
      <li parentName="ul">{`and more...`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Don't see the tag you need, no problem, just ask.`}</p>
    </blockquote>
    <br />
    <p><a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Get started now!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      